<template>
    <fieldset>
        <div class="wrap_line_heading">
        <h6 class="font-weight-semibold">Data Penanggung Jawab</h6>
        <div class="row">
        
            <div class="col-md-12 mt-2 mb-4">
            <b-form-checkbox
                v-model="isParent.row.arpj_is_same"
                value="Y"
                unchecked-value="N"
                @change="changeIsSame($event)"
                >
                Penanggung jawab adalah pasien
            </b-form-checkbox>
            </div>

            <div class="form-group col-md-3">
            <label>Hubungan Keluarga <small class="txt_mandatory">*</small></label>
            
            <v-select :disabled="isParent.row.arpj_is_same == 'Y'" placeholder="Pilih Hubungan Keluarga" v-model="isParent.row.arpj_hubungan_keluarga"
                :options="isParent.mHubunganKeluarga" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            <VValidate 
                name="Hubungan Keluarga" 
                v-model="isParent.row.arpj_hubungan_keluarga" 
                :rules="toValidate(isParent.mrValidation.arpj_hubungan_keluarga)"
            />
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arpj_hubungan_keluarga == 6">
                <label>Hubungan Keluarga Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'"  v-model="isParent.row.arpj_hubungan_keluarga_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Hubungan Keluarga Lainnya" />
                <VValidate 
                    name="Hubungan Keluarga Lainnya" 
                    v-model="isParent.row.arpj_hubungan_keluarga_text" 
                    :rules="toValidate(isParent.mrValidation.arpj_hubungan_keluarga_text)"
                />
            </div>


            <div class="form-group col-lg-3">
            <label>Nama Penanggung Jawab <small class="txt_mandatory">*</small></label>
            <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'" v-model="isParent.row.arpj_fullname" :formatter="isParent.$parent.normalText" type="text" name="namaLengkap" id="namaLengkap" class="form-control" placeholder="Nama Penanggung Jawab" />

            <VValidate 
                name="Nama Penanggung Jawab" 
                v-model="isParent.row.arpj_fullname" 
                :rules="toValidate(isParent.mrValidation.arpj_fullname)"
            />
            </div>

            <div class="form-group col-md-3">
                <label>Pekerjaan</label>
                <v-select :disabled="isParent.row.arpj_is_same == 'Y'" placeholder="Pilih Pekerjaan" v-model="isParent.row.arpj_pekerjaan"
                    :options="isParent.mPekerjaan" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arpj_pekerjaan == 99999">
                <label>Pekerjaan Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'" v-model="isParent.row.arpj_pekerjaan_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Pekerjaan Lainnya" />
                <VValidate 
                    name="Pekerjaan Lainnya" 
                    v-model="isParent.row.arpj_pekerjaan_text" 
                    :rules="toValidate(isParent.mrValidation.arpj_pekerjaan_text)"
                />
            </div>

            <div class="w-100"></div>
            <div class="form-group col-md-3">
            <label>Jenis Kelamin <small class="txt_mandatory">*</small></label>
            <v-select :disabled="isParent.row.arpj_is_same == 'Y'" placeholder="Pilih Jenis Kelamin" v-model="isParent.row.arpj_gender"
                :options="Config.mr.StatusKelamin" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            <VValidate 
                name="Jenis Kelamin" 
                v-model="isParent.row.arpj_gender" 
                :rules="toValidate(isParent.mrValidation.arpj_gender)"
            />
            </div>

            <div class="form-group col-md-3">
            <label>Agama</label>
            <v-select :disabled="isParent.row.arpj_is_same == 'Y'" placeholder="Pilih Agama" v-model="isParent.row.arpj_agama"
                :options="isParent.mAgama" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arpj_agama == 99999">
                <label>Agama Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'" v-model="isParent.row.arpj_agama_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Agama Lainnya" />
                <VValidate 
                    name="Agama Lainnya" 
                    v-model="isParent.row.arpj_agama_text" 
                    :rules="toValidate(isParent.mrValidation.arpj_agama_text)"
                />
            </div>

            <div class="form-group col-md-4">
           
            <label>No Handphone</label>
            <div class="input-group">
                <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">+62</span>
                </div>
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'" v-model="isParent.row.arpj_phone_number" :formatter="isParent.$parent.number" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="No Telepon" />
            </div>
           
            </div>
        </div>
        </div>
        <hr>
        <div class="wrap_line_heading">
        <h6 class="font-weight-semibold">Alamat Domisili</h6>
        <div class="row">
            <div class="col-md-12 mt-2 mb-4">
            <b-form-checkbox
                v-model="isParent.row.arpj_is_same_dom"
                value="Y"
                unchecked-value="N"
                @change="changeIsSameDom($event)"
                >
                Alamat Domisili sama dengan pasien
            </b-form-checkbox>
            </div>

            <div class="form-group col-md-3">
                <label>Provinsi <small class="txt_mandatory">*</small></label>
                <v-select :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" placeholder="Pilih Provinsi" v-model="isParent.row.arpj_provinsi"
                    @input="selectKota($event,'mCityPJ',['arpj_kota','arpj_kecamatan','arpj_kelurahan','arpj_postal_code'])"
                    :options="isParent.mProvinsiPJ" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
                <VValidate 
                    name="Provinsi" 
                    v-model="isParent.row.arpj_provinsi" 
                    :rules="toValidate(isParent.mrValidation.arpj_provinsi)"
                />
            </div>

            <div class="form-group col-md-3">
                <label>Kabupaten/Kota <small class="txt_mandatory">*</small></label>
                <v-select :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" placeholder="Pilih Kabupaten/Kota " v-model="isParent.row.arpj_kota"
                    @input="selectKec(isParent.row.arpj_provinsi,$event,'mDistrictPJ',['arpj_kecamatan','arpj_kelurahan','arpj_postal_code'])"                
                    :options="isParent.mCityPJ" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
                <VValidate 
                    name="Kabupaten/Kota " 
                    v-model="isParent.row.arpj_kota" 
                    :rules="toValidate(isParent.mrValidation.arpj_kota)"
                />
            </div>

            <div class="form-group col-md-3">
                <label>Kecamatan<small class="txt_mandatory">*</small></label>
                <v-select :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" placeholder="Pilih Kecamatan" v-model="isParent.row.arpj_kecamatan"
                    @input="selectKel(isParent.row.arpj_provinsi,isParent.row.arpj_kota,$event,'mSubDistrictPJ',['arpj_kelurahan','arpj_postal_code'])" 
                    :options="isParent.mDistrictPJ" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
                <VValidate 
                    name="Kecamatan" 
                    v-model="isParent.row.arpj_kecamatan" 
                    :rules="toValidate(isParent.mrValidation.arpj_kecamatan)"
                />
            </div>
            <div class="w-100"></div>
            <div class="form-group col-md-3">
                <label>Kelurahan<small class="txt_mandatory">*</small></label>
                <v-select :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" placeholder="Pilih Kelurahan" v-model="isParent.row.arpj_kelurahan"
                    @input="selectPostal(isParent.row.arpj_provinsi,isParent.row.arpj_kota,isParent.row.arpj_kecamatan,$event,'arpj_postal_code')" 
                    :options="isParent.mSubDistrictPJ" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
                <VValidate 
                    name="Kelurahan" 
                    v-model="isParent.row.arpj_kelurahan" 
                    :rules="toValidate(isParent.mrValidation.arpj_kelurahan)"
                />
            </div>

            <div class="form-group col-md-2">
            <label>Kode Pos <small class="txt_mandatory">*</small></label>
            <input type="text" disabled v-model="isParent.row.arpj_postal_code" name="PenanggungJawab" id="PenanggungJawab" class="form-control" placeholder=" "
                value="11620" readonly>
            </div>

            <div class="form-group col-md-3">
            <label>RT/RW <small class="txt_mandatory">*</small></label>
            <div class="input-group">
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" v-model="isParent.row.arpj_rt" :formatter="isParent.$parent.number" type="text" name="RT" id="RT" class="form-control" placeholder="RT" />
                <b-form-input :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" v-model="isParent.row.arpj_rw" :formatter="isParent.$parent.number" type="text" name="RW" id="RW" class="form-control" placeholder="RW" />
            </div>

            <VValidate 
                name="RT" 
                v-model="isParent.row.arpj_rt" 
                :rules="toValidate(isParent.mrValidation.arpj_rt)"
            />
            <VValidate 
                name="RW" 
                v-model="isParent.row.arpj_rw" 
                :rules="toValidate(isParent.mrValidation.arpj_rw)"
            />
            </div>
            <div class="form-group col-md-9">
            <label>Alamat KTP <small class="txt_mandatory">*</small></label>
            <b-textarea :disabled="isParent.row.arpj_is_same == 'Y'||isParent.row.arpj_is_same_dom == 'Y'" v-model="isParent.row.arpj_address" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control" placeholder="Alamat"></b-textarea>
            <VValidate 
                name="Alamat KTP" 
                v-model="isParent.row.arpj_address" 
                :rules="toValidate(isParent.mrValidation.arpj_address)"
            />
            </div>
        </div>

        </div>
    </fieldset>
</template>


<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'

export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    }, 
    methods: {
        changeIsSame(e){
            if(e == "Y"){
                this.isParent.row.arpj_hubungan_keluarga = 1
                this.isParent.row.arpj_fullname = this.isParent.row.ap_fullname
                this.isParent.row.arpj_pekerjaan = this.isParent.row.ap_pekerjaan
                this.isParent.row.arpj_pekerjaan_text = this.isParent.row.ap_pekerjaan_text
                this.isParent.row.arpj_gender = this.isParent.row.ap_gender
                this.isParent.row.arpj_agama = this.isParent.row.ap_agama
                this.isParent.row.arpj_agama_text = this.isParent.row.ap_agama_text
                this.isParent.row.arpj_phone_number = this.isParent.row.ap_phone_number

                this.isParent.row.arpj_address = this.isParent.row.ap_address
                this.isParent.row.arpj_rt = this.isParent.row.ap_rt
                this.isParent.row.arpj_rw = this.isParent.row.ap_rw
                this.isParent.row.arpj_kelurahan = this.isParent.row.ap_kelurahan
                this.isParent.row.arpj_kecamatan = this.isParent.row.ap_kecamatan
                this.isParent.row.arpj_kota = this.isParent.row.ap_kota
                this.isParent.row.arpj_provinsi = this.isParent.row.ap_provinsi
                this.isParent.row.arpj_postal_code = this.isParent.row.ap_postal_code
           
                this.mProvinsiPJ = this.mProvinsiDom
                this.mCityPJ = this.mCityDom
                this.mDistrictPJ = this.mDistrictDom
                this.mSubDistrictPJ = this.mSubDistrictDom
            }else{
                this.isParent.row.arpj_hubungan_keluarga = null 
                this.isParent.row.arpj_fullname = null 
                this.isParent.row.arpj_pekerjaan = null
                this.isParent.row.arpj_pekerjaan_text = null
                this.isParent.row.arpj_gender = null 
                this.isParent.row.arpj_agama = null 
                this.isParent.row.arpj_agama_text = null
                this.isParent.row.arpj_phone_number = null 

                this.isParent.row.arpj_address = null 
                this.isParent.row.arpj_rt = null 
                this.isParent.row.arpj_rw = null 
                this.isParent.row.arpj_kelurahan = null 
                this.isParent.row.arpj_kecamatan = null 
                this.isParent.row.arpj_kota = null 
                this.isParent.row.arpj_provinsi = null
                this.isParent.row.arpj_postal_code = null
            }
        },
        changeIsSameDom(e){
            if(e == "Y"){
                this.isParent.row.arpj_address = this.isParent.row.ap_address
                this.isParent.row.arpj_rt = this.isParent.row.ap_rt
                this.isParent.row.arpj_rw = this.isParent.row.ap_rw
                this.isParent.row.arpj_kelurahan = this.isParent.row.ap_kelurahan
                this.isParent.row.arpj_kecamatan = this.isParent.row.ap_kecamatan
                this.isParent.row.arpj_kota = this.isParent.row.ap_kota
                this.isParent.row.arpj_provinsi = this.isParent.row.ap_provinsi
                this.isParent.row.arpj_postal_code = this.isParent.row.ap_postal_code
           
                this.mProvinsiPJ = this.mProvinsiDom
                this.mCityPJ = this.mCityDom
                this.mDistrictPJ = this.mDistrictDom
                this.mSubDistrictPJ = this.mSubDistrictDom
            }else{
                this.isParent.row.arpj_address = null 
                this.isParent.row.arpj_rt = null 
                this.isParent.row.arpj_rw = null 
                this.isParent.row.arpj_kelurahan = null 
                this.isParent.row.arpj_kecamatan = null 
                this.isParent.row.arpj_kota = null 
                this.isParent.row.arpj_provinsi = null
                this.isParent.row.arpj_postal_code = null
            }
        },
        toValidate(val){
            return {...val}
        },
        selectKota(provinsi,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                type : 'select-city'
            }

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
            })
        },
        selectKec(provinsi,kota,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                kota : kota,
                type : 'select-kec'
            }

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
            })
            
        },
        selectKel(provinsi,kota,kecamatan,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                kota : kota,
                kecamatan : kecamatan,
                type : 'select-kel'
            }

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
            })
        },
        selectPostal(provinsi,kota,kecamatan,kelurahan,postal){
            let data = {
                provinsi : provinsi,
                kota : kota,
                kecamatan : kecamatan,
                kelurahan : kelurahan,
                type : 'select-postal'
            }

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent.row[postal] = res.data
            })
        }
    }
}
</script>
