<template>
    <fieldset>
        <div class="wrap_line_heading">
        <h6 class="font-weight-semibold">Memberi kewenangan informasi kesehatan pasien kepada</h6>
        <div class="row">
            <div class="col-md-12 mt-2 mb-4">
            <b-form-checkbox
                v-model="isParent.row.arki_is_same"
                value="Y"
                unchecked-value="N"
                @change="changeIsSame($event)"
                >
                Penerima kewenangan informasi sama dengan penanggung jawab
            </b-form-checkbox>
            </div>


            <div class="form-group col-md-3">
            <label>Hubungan Keluarga <small class="txt_mandatory">*</small></label>
            
            <v-select :disabled="isParent.row.arki_is_same == 'Y'" placeholder="Pilih Hubungan Keluarga" v-model="isParent.row.arki_hubungan_keluarga"
                :options="isParent.mHubunganKeluarga" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            <VValidate 
                name="Hubungan Keluarga" 
                v-model="isParent.row.arki_hubungan_keluarga" 
                :rules="toValidate(isParent.mrValidation.arki_hubungan_keluarga)"
            />
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arki_hubungan_keluarga == 6">
                <label>Hubungan Keluarga Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arki_is_same == 'Y'" v-model="isParent.row.arki_hubungan_keluarga_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Hubungan Keluarga Lainnya" />
                <VValidate 
                    name="Hubungan Keluarga Lainnya" 
                    v-model="isParent.row.arki_hubungan_keluarga_text" 
                    :rules="toValidate(isParent.mrValidation.arki_hubungan_keluarga_text)"
                />
            </div>


            <div class="form-group col-lg-3">
            <label>Nama Penanggung Jawab <small class="txt_mandatory">*</small></label>
            <b-form-input :disabled="isParent.row.arki_is_same == 'Y'" v-model="isParent.row.arki_fullname" :formatter="isParent.$parent.normalText" type="text" name="namaLengkap" id="namaLengkap" class="form-control" placeholder="Nama Penanggung Jawab" />

            <VValidate 
                name="Nama Penanggung Jawab" 
                v-model="isParent.row.arki_fullname" 
                :rules="toValidate(isParent.mrValidation.arki_fullname)"
            />
            </div>

            <div class="form-group col-md-3">
                <label>Pekerjaan</label>
                <v-select :disabled="isParent.row.arki_is_same == 'Y'" placeholder="Pilih Pekerjaan" v-model="isParent.row.arki_pekerjaan"
                    :options="isParent.mPekerjaan" label="text" :clearable="true"
                    :reduce="v=>v.value">
                </v-select>
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arki_pekerjaan == 99999">
                <label>Pekerjaan Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arki_is_same == 'Y'" v-model="isParent.row.arki_pekerjaan_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Pekerjaan Lainnya" />
                <VValidate 
                    name="Pekerjaan Lainnya" 
                    v-model="isParent.row.arki_pekerjaan_text" 
                    :rules="toValidate(isParent.mrValidation.arki_pekerjaan_text)"
                />
            </div>

            <div class="w-100"></div>
            <div class="form-group col-md-3">
            <label>Jenis Kelamin <small class="txt_mandatory">*</small></label>
            <v-select :disabled="isParent.row.arki_is_same == 'Y'" placeholder="Pilih Jenis Kelamin" v-model="isParent.row.arki_gender"
                :options="Config.mr.StatusKelamin" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            <VValidate 
                name="Jenis Kelamin" 
                v-model="isParent.row.arki_gender" 
                :rules="toValidate(isParent.mrValidation.arki_gender)"
            />
            </div>

            <div class="form-group col-md-3">
            <label>Agama</label>
            <v-select :disabled="isParent.row.arki_is_same == 'Y'" placeholder="Pilih Agama" v-model="isParent.row.arki_agama"
                :options="isParent.mAgama" label="text" :clearable="true"
                :reduce="v=>v.value">
            </v-select>
            </div>
            
            <div class="form-group col-md-3" v-if="isParent.row.arki_agama == 99999">
                <label>Agama Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input :disabled="isParent.row.arki_is_same == 'Y'" v-model="isParent.row.arki_agama_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Agama Lainnya" />
                <VValidate 
                    name="Agama Lainnya" 
                    v-model="isParent.row.arki_agama_text" 
                    :rules="toValidate(isParent.mrValidation.arki_agama_text)"
                />
            </div>

            <div class="form-group col-md-4">
           
            <label>No Handphone</label>
            <div class="input-group">
                <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">+62</span>
                </div>
                <b-form-input :disabled="isParent.row.arki_is_same == 'Y'" v-model="isParent.row.arki_phone_number" :formatter="isParent.$parent.number" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="No Telepon" />
            </div>
            </div>

        </div>
        </div>
        
        <div class="wrap_line_heading bg_statement">
        <h6 class="font-weight-semibold">Pernyataan Pasien</h6>
        <h6>Saya, yang bertanda tangan di bawah ini sesuai dengan identitas pendaftar menyatakan:</h6>
        <div class="table-responsive wrap_agreement">
            <ul>
                <li>
                Data yang saya/pendaftar isi adalah data yang benar dan atau sesuai identitas yang berlaku.</li>
                <li>
                Telah mendapat informasi tentang hak dan kewajiban pasien di RS Harapan Magelang dengan media
                leaflet.
                </li>
                <li>
                Bersedia memberikan data dengan benar dan menerima pendidikan kesehatab untuk pasien/keluarga.
                </li>
                <li>
                Bersedia dan setuju untuk dilakukan pemeriksaan, penegakan diagnosa, perawatan, pengobatan,
                dan/atau tindakan medis kepada pasien rawat jalan/rawat darurat di RS Harapan Magelang
                oleh dokter IGD/Umum/Spesialis/Dokter Gigi/Spesialis Gigi, dan tenaga kesehatan lainnya yang
                diperlukan untuk membantu kesembuhan.
                </li>
                <li>
                Apabila instansi/asuransi kesehatan pemerintah/swasta tempat saya bekerja menanggung pembiayaan
                kesehatan saya, saya
                memberi wewenang kepada RS Harapan Magelang untuk memberi tagihan atas semua pelayanan serta
                tindakan medis yang diberikan, termasuk mengerluarkan
                informasi medis dan fotokopi hasil pemeriksaan saya apabila dibutuhkan dalam proses klaim sesuai
                prosedut rumah sakit.
                </li>
                <li>
                Bertanggung jawab atas keamanan barang-barang berharga milik pasien/keluarga selama berada
                dilingkungan rumah sakit.
                </li>
                <li>
                Apabila tindakan dan pelayanan kesehatan tersebut tidak sesuai dengan nilai dan keyakinan yang
                saya anut, saya dapat menolak setelah memahami
                segala resiko yang sudah dijelaskan oleh dokter, perawat dan tenaga kesehatan lain dengan mengisi
                formulir penolakan tindakan.
                </li>
                <li>Apabila pasien dalam kondisi tidak sadar/mengalami gangguan jiwa/belum cukup umut (bayi atau
                anak), maka pasien/keluarha menunjuk orang yang
                diberi kuasa untuk menerima informasi media dan memberikan persetujuan tindakan medis yaitu:
                <br>
                <strong id="arki_fullname">{{isParent.row.arki_fullname||"-"}}</strong> Hubungan dengan pasien <strong id="arki_hubungan_keluarga_text">
                    {{getConfigDynamic(isParent.mHubunganKeluarga,isParent.row.arki_hubungan_keluarga)||"-"}}
                </strong> No. Selular <strong id="arki_phone_number">+62{{isParent.row.arki_phone_number||"-"}}</strong></li>
                <li>
                Privasi khusus kepada pasien/keluarga dalam pengambilan keputusan pemberian terapi, tindakan
                medis, dan tindakan keperawatan, dalam rangka menghargai keyakinan atau sesuai
                dengan nilai budaya, agama, dan kepercayaan yang diyakini pasien yaitu berupa:
                <div class="form-group">
                <b-form-input v-model="isParent.row.arki_reason" :formatter="isParent.$parent.normalText" name="ki_reason" class="form-control txt_inp_border mt-2" validate='true' placeholder="Contoh: Tidak boleh transfusi, dll. "/>
                </div>
                </li>
                <li>
                Bila saya menggunakan jaminan BPJS saya menyetujui:
                <ol type="a">
                    <li>
                    Penggunaan obar generic/formularium nasional atau obat-obat yang ada dalam formularium RS.
                    </li>
                    <li>
                    Apabila dalam pengobatan periode ini saya menolak menggunakan obat generik atau menginginkan
                    obat yang tidak ada dalam fornas/formulanium RS atau tidak sesuai prosedur pelayanan
                    pasien BPJS. maka saya bersedia mengubah cara pembayaran saya menjadi biaya sendiri tidak
                    menggunakan jaminan BPJS.
                    </li>
                </ol>
                </li>
            </ul>
        </div>
        </div>

        

    </fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'

export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    }, 
    methods: {
        changeIsSame(e){
            if(e == "Y"){
                this.isParent.row.arki_hubungan_keluarga = this.isParent.row.arpj_hubungan_keluarga 
                this.isParent.row.arki_fullname = this.isParent.row.arpj_fullname
                this.isParent.row.arki_pekerjaan = this.isParent.row.arpj_pekerjaan
                this.isParent.row.arki_pekerjaan_text = this.isParent.row.arpj_pekerjaan_text
                this.isParent.row.arki_gender = this.isParent.row.arpj_gender
                this.isParent.row.arki_agama = this.isParent.row.arpj_agama
                this.isParent.row.arki_agama_text = this.isParent.row.arpj_agama_text
                this.isParent.row.arki_phone_number = this.isParent.row.arpj_phone_number
            }else{
                this.isParent.row.arki_hubungan_keluarga = null
                this.isParent.row.arki_fullname = null
                this.isParent.row.arki_pekerjaan = null
                this.isParent.row.arki_pekerjaan_text = null
                this.isParent.row.arki_gender = null
                this.isParent.row.arki_agama = null
                this.isParent.row.arki_agama_text = null
                this.isParent.row.arki_phone_number = null
            }
        },
        toValidate(val){
            return {...val}
        },
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index]['text']
                }
            }
            return text
        },
    }
}
</script>
